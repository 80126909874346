<template>
  <page-loader v-if="loading" />
  <component
    v-else
    v-model="model"
    :is="questionsComponent"
    :save-data="saveData"
    :on-submit="onSubmit"
  />
</template>

<script>
import HeaderWithBack from "../../components/Project/HeaderWithBack";
import {
  componentByType,
  nextQuestionType,
} from "../../utils/service/Questionnaire";
import PageLoader from "../../components/PageLoader";

export default {
  name: "PublicQuestionnaire",
  components: {
    PageLoader,
    HeaderWithBack,
  },
  data() {
    return {
      model: {},
      loading: false,
    };
  },
  async created() {
    await this.updateModel();
  },
  computed: {
    questionsComponent() {
      return componentByType(this.$route.params.type);
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.type === "data_usage_purpose") {
      return next({
        name: "public_questionnaire_data_usage_purpose",
        params: { ...to.params },
      });
    }
    next();
  },
  methods: {
    async updateModel() {
      this.loading = true;
      try {
        this.model =
          (
            await this.$axios.get(
              `/api/v1/business/data/${this.$route.params.type}`
            )
          )?.params || this.model;
      } catch {
        // do nothing
      } finally {
        this.loading = false;
      }
    },
    async saveData(model) {
      try {
        this.$event(`public.${this.$route.params.type}.submitted`, {
          locale: this.$route.params?.locale || "en",
        });

        return await this.$axios.post(`/api/v1/business/data`, {
          locale: this.$route.params?.locale || "en",
          type: this.$route.params.type,
          params: model,
        });
      } catch (e) {
        this.$log.notify(e);
      }
    },
    onSubmit() {
      const nextType = nextQuestionType(this.$route.params.type);

      if (nextType) {
        return this.$router
          .push({
            name: "public_questionnaire",
            params: {
              ...this.$route.params,
              type: nextType,
            },
          })
          .catch(() => {});
      }

      return this.$router
        .push({
          name: "public_questionnaire_thank_you",
          params: {
            ...this.$route.params,
          },
        })
        .catch(() => {});
    },
  },
  watch: {
    "$route.params.type"() {
      this.updateModel();
    },
  },
};
</script>

<style lang="scss" scoped></style>
